import PropTypes from "prop-types";
import React from 'react'
import { Grid } from '@material-ui/core';

function NavigationCard(props) {
    return (
        <wired-card class="frame-component NavigationCard" style={{ minWidth: "15em", pointerEvents: "none" }}>
                <Grid container alignItems="center">
                    <Grid item xs={12}><wired-card style={{ backgroundColor: "rgba(192,192,192,0.7)", height: props.imageSizes[props.size].height, width: props.imageSizes[props.size].width }}></wired-card></Grid>
                    <Grid item xs={12}><wired-card style={{ width: "100%", height: "100%" }} fill="rgba(192,192,192,0.7)"></wired-card></Grid>
                    <Grid item container justify="space-around" xs={12}>
                        <Grid item xs={4}>
                            <wired-button style={{ pointerEvents: "none" }}>Button</wired-button>
                        </Grid>
                        <Grid item xs={4}>
                            <wired-button style={{ pointerEvents: "none" }}>Button</wired-button>
                        </Grid>
                    </Grid>
                </Grid>
            </wired-card>
    )
}

NavigationCard.propTypes = {
  activeAnno: PropTypes.any,
  color: PropTypes.any,
  elemCount: PropTypes.any,
  imageSizes: PropTypes.any,
  size: PropTypes.any
}

export default NavigationCard


