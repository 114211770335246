import PropTypes from "prop-types";
import React from "react";
import ClickableComponent from "../ClickableComponent"
import "wired-elements";
import { Grid } from '@material-ui/core'


function randBetween(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function randomComponent(elementOptions) {
    const rand = randBetween(0, elementOptions.length - 1)
    const removedElem = elementOptions.splice(rand, 1);
    return removedElem;
}

function Wireframe4(props) {
    let variableTools = ['Search', 'Checkbox', 'Slider']
    let elemCount = 1;
    let menu = [];
    for (let i = 0; i < props.randomizedProperties.menuItems; i++) {
        elemCount++;
        // // const randBool = randBetween(0, 100) > 50;
        // const layout = randBool ? "horizontal" : "vertical";
        menu.push(<Grid item xs={12} sm={2}>
                    <ClickableComponent
                        key={elemCount}
                        elemCount={elemCount}
                        {...props}
                        component={'MenuOption'}
                    />
                </Grid>
        )
    }

    let selectorArray = [];
    // for (let i=0; i < props.randomizedProperties.toolbar.small; i++){    
    for (let i = 0; i < 2; i++) {
        elemCount++;
        console.log(elemCount, "ELEMCOUNT")
        selectorArray.push(
            <Grid item style={{ margin: "auto" }}>
                <ClickableComponent
                    key={elemCount}
                    elemCount={elemCount}
                    component={"Selector"}
                    {...props}
                />
            </Grid>
        )
    }
    let variableToolsArray = [];
    for (let i = 0; i < props.randomizedProperties.toolbar.small; i++) {
        elemCount++;
        let selection = randomComponent(variableTools);
        variableToolsArray.push(
            <Grid item style={{ margin: "auto" }}>
                <ClickableComponent
                    key={elemCount}
                    elemCount={elemCount}
                    component={selection}
                    {...props}
                />
            </Grid>
        )
    }
    return (
        <div className="LayoutWires">
            <Grid container spacing={2}>
                <Grid item xs={12} container>
                    <Grid item xs={12} sm={2} style={{ textAlign: "left" }}><div className='logo'><wired-icon-button>App Name</wired-icon-button></div></Grid>
                    <Grid item xs={12} sm={8} style={{ textAlign: "left" }}>
                        <wired-card style={{width: "100%"}}>
                            <Grid container justify="space-evenly">
                                {menu}
                            </Grid>
                        </wired-card>
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    {variableToolsArray}
                    {selectorArray}
                </Grid>
                <Grid item xs={12}>
                <ClickableComponent
                            key={elemCount+1}
                            elemCount={elemCount+1}
                            size={"large"}
                            component={"Table"}
                            layout={"horizontal"}
                            {...props}
                        />
                </Grid>
            </Grid>
        </div>
    )
}

Wireframe4.propTypes = {
  annotations: PropTypes.any,
  clickAction: PropTypes.any,
  colors: PropTypes.any,
  imageSizes: PropTypes.any,
  randomizedProperties: PropTypes.shape({
    menuItems: PropTypes.any,
    toolbar: PropTypes.shape({
      small: PropTypes.any
    })
  })
}

export default Wireframe4