import PropTypes from "prop-types";
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Icon from '@material-ui/core/Icon'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
export default function CopyDialogModal(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleClickOpen = () => {
      console.log(props)
        setOpen(true);
      };
    return (
      <div>
        <Button id="Copy-dialog-button" style={{display: "none"}} variant="outlined" color="primary" onClick={handleClickOpen}>
            Slide in alert dialog
        </Button>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            //   onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title">{"Copied!"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                Your annotations have been copied to your clipboard! Now, close this window and press ctrl+v to paste them to {props.appClicked}.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}><Icon><ThumbUpIcon/></Icon>&nbsp;OK</Button>
            </DialogActions>
        </Dialog>
      </div>
    );
  }

CopyDialogModal.propTypes = {
  copyData: PropTypes.func,
  stage: PropTypes.string,
  updateStage: PropTypes.func
}
  