import 'wired-elements';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import ClickableComponent from '../ClickableComponent';

function Wireframe2(props) {
    let elemCount = 1;
    let menu = [];
    for (let i = 0; i < props.randomizedProperties.menuItems; i++) {
        elemCount++;
        // const randBool = randBetween(0, 100) > 50;
        // const layout = randBool ? "horizontal" : "vertical";
        menu.push(<Grid item xs={12} sm={2}>
                    <ClickableComponent
                        key={elemCount}
                        elemCount={elemCount}
                        {...props}
                        component={'MenuOption'}
                    />
                </Grid>
        )
    }
    let buttonArray = [];
    for (let i = 0; i < props.randomizedProperties.toolbar.medium; i++) {
        elemCount++;
        buttonArray.push(
            <Grid item xs={12} key={i}>
                <ClickableComponent
                    key={elemCount}
                    elemCount={elemCount}
                    component={"Button"}
                    {...props}
                />
            </Grid>
        )
    }
    let selectorArray = [];
    for (let i = 0; i < props.randomizedProperties.toolbar.medium; i++) {
        elemCount++;
        selectorArray.push(
            <Grid xs={12} item key={i}>
                <ClickableComponent
                    key={elemCount}
                    elemCount={elemCount}
                    component={"Selector"}
                    {...props}
                />
            </Grid>
        )
    }
    // let manualElemCount1 = elemCount + 1
    return (
        <div className="LayoutWires">
            <Grid container spacing={2}>
                <Grid item xs={12} container>
                    <Grid item xs={12} sm={3} style={{ textAlign: "left" }}><div className='logo'><wired-icon-button>App Name</wired-icon-button></div></Grid>
                    <Grid item xs={12} sm={6}>
                        <div>
                            <Grid container justify="space-evenly">
                                {menu}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <div style={{ width: "100%" }}>
                            <Grid container spacing={4} justify="center">
                                {buttonArray}
                                {selectorArray}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <ClickableComponent
                            key={elemCount+1}
                            elemCount={elemCount+1}
                            size={"large"}
                            component={"CardGallery"}
                            layout={"horizontal"}
                            {...props}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

Wireframe2.propTypes = {
    annotations: PropTypes.any,
    clickAction: PropTypes.any,
    colors: PropTypes.any,
    imageSizes: PropTypes.any,
    randomizedProperties: PropTypes.shape({
        menuItems: PropTypes.any,
        toolbar: PropTypes.shape({
            medium: PropTypes.any
        })
    })
}

export default Wireframe2