import React from 'react';
import { Card, CardContent, Typography, Divider, Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
    },
    card : {
        margin: "1em",
        cursor: 'pointer'
    },
    type: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '.5em',
    },
    pov: {
        textAlign: 'left'
    },
    annoBox: {
        flexGrow: 1,
        margin: '.5em',
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
      },
}));
export default function AnnoCard(props) {
    const classes = useStyles();
    // const showCard = () => props.setShow(props.annotationText.length > 0)
    let StatusSymbol = 
        props.active ? <Icon><EditIcon/></Icon> :
            props.lock ? <Icon><CheckBoxIcon/></Icon> :
                    <Icon><CheckBoxOutlineBlankIcon/></Icon>    
    return (
        <Card className={classes.card} onClick={props.openPopper}>               
        <CardContent>
            <Grid container spacing={1}>
                <Grid item xs={12} container direction="row" justify="space-between">
                <Typography component="h3">
                    {props.type}    
                </Typography>
                {StatusSymbol}
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid xs={12} item>
                <Typography component="p" className={classes.pov}>
                <span className="annotation-textfield" data-key={props.elemCount} data-type={props.type}>
                    {props.annotationText}
                </span>  
            </Typography>
                </Grid>
            </Grid>
        </CardContent>
        </Card>
    )

}