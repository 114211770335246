import PropTypes from "prop-types";
import React from 'react'
import { TextareaAutosize, Button, Typography } from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
      margin: 'auto',
      maxWidth: '800px',
      padding: theme.spacing(4)
  },
  formElement: {
    padding: theme.spacing(2)
  },
  exampleElement: {
    padding: theme.spacing(1)
  }
}));
function WelcomeScreen(props) {
  const classes = useStyles();
  // const theme = useTheme();
  return (
      <div className={"POV-form "+classes.root}>
      <div className="POV-form-section">
        <div className={"POV-example "+classes.formElement}>
          <div className={classes.exampleElement}>
            <Typography component="h2">
              Example POV Statement:
            </Typography>
          </div>
          <div className={classes.exampleElement}>
            <Typography component="p">
              "Caroline is a 26-year-old single mom who loves sci-fi movies. She needs a way to access new and entertaining content in a way that allows her to consume it at her own pace, while making her feel excited about discovering new shows to share with her friends."
            </Typography>
          </div>
        </div>
        <div className={"POV-textarea "+classes.formElement}>
          <TextareaAutosize placeholder="Please enter your POV statement" onChange={(event) => props.setPov(event.target.value)} style={{ minHeight: '4em', width: '100%' }}></TextareaAutosize>
        </div>
        <div className={"POV-save-button "+classes.formElement} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="primary" disabled={props.pov.length > 0 ? false:true} variant="contained" onClick={props.submitPOV}><PublishIcon/>&nbsp;Submit</Button>
        </div>
      </div>
    </div>
  )
}

WelcomeScreen.propTypes = {
  setPov: PropTypes.func,
  submitPOV: PropTypes.any
}

export default WelcomeScreen;