import PropTypes from "prop-types";
import React from 'react'
import { Grid } from '@material-ui/core';




function TableRow(props) {
    let columns = '';
    if (props.columns === 5) {
        columns = 6
    }
    else {
        columns = props.columns
    }
    let columnArray = []
    let headerText = window.innerWidth > 600 ? "Header": "H"
    if (props.rowIndex === 0) {
        for (let i = 0; i < columns; i++) {
            columnArray.push(
                <Grid item xs={12 / columns} key={i}>
                    <wired-card style={{ width: "75%", height: "75%", margin: "5px" }}>{headerText} {i + 1}</wired-card>
                </Grid>
            )
        }
    }
    else {
        for (let i = 0; i < columns; i++) {
            columnArray.push(
                <Grid item xs={12 / columns} key={i}>
                    <wired-card style={{ width: "75%", height: "75%", margin: "5px" }} fill="rgba(192,192,192,0.7)" ></wired-card>
                </Grid>)
        }
    }
    return (
        <Grid item xs={12} container>
            <Grid item xs={12}>
                <wired-divider />
            </Grid>
            <Grid container xs={12} item>
                {columnArray}
            </Grid>
        </Grid>
    )
}

TableRow.propTypes = {
  columns: PropTypes.number,
  rowIndex: PropTypes.number
}

function Table(props) {
    let rowArray = [];
    for (let i = 0; i < props.randomizedProperties.grid[props.size].rows; i++) {
        rowArray.push(<TableRow key={i} columns={props.randomizedProperties.grid[props.size].columns} rowIndex={i} />)
    }
    return (
        <wired-card class="frame-component Table"><Grid container>{rowArray}</Grid></wired-card>
    )
}

Table.propTypes = {
  activeAnno: PropTypes.any,
  color: PropTypes.any,
  elemCount: PropTypes.any,
  randomizedProperties: PropTypes.shape({
    grid: PropTypes.any
  }),
  size: PropTypes.any
}

export default Table