import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import copy from 'copy-to-clipboard'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyDialogModal from './CopyDialogModal'

const options = [
    { text: 'Miro', icon: 'https://www.google.com/s2/favicons?sz=18&domain=miro.com' },
    { text: 'Roam Research', icon: "https://www.google.com/s2/favicons?sz=18&domain=roamresearch.com" }
]; //, 'Plain Text'

export default function DropdownButton(props) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const copyData = (selected) => {
        document.getElementById('Copy-dialog-button').click();
        const annotations = Array.from(document.getElementsByClassName("annotation-textfield"));
        // console.log(this.state.annotations)
        
        const excelData = formatData()
        copy(excelData, {
            format: "text/plain",
        })
        // alert("Your annotations have been copied to your clipboard! Now, close this window and press ctrl+v to paste them to Miro.")

        function formatData() {
            if (options[selected].text === "Miro") {
                let stateArray = [];
                let indyAnnotation = [];
                for (let i = 0; i < annotations.length; i++) {
                    indyAnnotation.push(annotations[i].dataset.type + "-" + annotations[i].innerHTML)
                }
                stateArray.push(indyAnnotation)
                return `Point of View (POV): ${props.povValue}\n${stateArray.map(lines => lines.join("\t")).join("\n")}`;
            } else if (options[selected].text === "Roam Research") {
                let annoText = annotations.map(lines => {
                    console.log(lines);
                    return `\t- [${lines.dataset.key}]([[ConstrUX Requirement]]). [[${lines.dataset.type}]]: ${lines.innerHTML} \n\t\t- User Personas:: \n\t\t- Pain Points:: \n\t\t- Benefits:: \n\t\t- Related Features:: \n\t\t- Importance To User:: {{slider}}`
                }).join('\n');
                return `[[Point of View (POV) Statement]]: ${props.povValue}\n${annoText}`
            }
        }
    }


    const handleClick = (index) => {
        let selected = index === undefined ? selectedIndex : index;
        console.info(selected, `You clicked ${options[selected].text}`);
        copyData(selected);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        console.log(selectedIndex, index);
        handleClick(index);
        setOpen(false);
    };

    const handleToggle = () => {
        console.log("handleToggle");
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup variant="outlined" color="primary" ref={anchorRef} aria-label="split button">
                    <Button color="primary" onClick={handleClick}><FileCopyIcon />&nbsp;Copy</Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={'copy'+index}
                                                // disabled={index === 2}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
            <CopyDialogModal appClicked={options[selectedIndex].text}/>
        </Grid>
    );
}