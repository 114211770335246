import { Backdrop, Box, CircularProgress, Hidden, IconButton, Slide, Tooltip, Typography } from '@material-ui/core';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilledWhite';
import ReplayIcon from '@material-ui/icons/Replay';
import PropTypes from 'prop-types';
import React from 'react';

import Gif from './Gif';

// Just shows the time, taking app state time as input prop
const Timer = function (props) {
  return (
    <Box>
      {props.timer} : {props.seconds}
    </Box>
  );
};

Timer.propTypes = {
  seconds: PropTypes.any,
  timer: PropTypes.any
}

// Resets the timer on click and clear the setInterval
const Reset = function (props) {
  return (
    <IconButton style={{ zIndex: "2001" }} variant="contained" onClick={props.onClickReset} ><ReplayIcon /></IconButton>

  );
};

Reset.propTypes = {
  onClickReset: PropTypes.any
}

function Control(props) {
  const absolute = { position: 'absolute', width: '100%', height: '100%' }
  if (!props.init) {
    return (
      <IconButton onClick={props.start} id="timer-start-pause-button">
        {props.paused ? <PlayCircleFilledIcon /> : "Pause"}
      </IconButton>
    )
  } else if (!props.paused) {
    return (
      <Tooltip title={props.minutes + ":" + props.seconds} >
        <Box position="relative">
          <CircularProgress variant="static" value={props.progress()} style={absolute} />
          {!props.paused && <IconButton onClick={props.pause}><PauseCircleFilledIcon /></IconButton>}
        </Box>
      </Tooltip>
    )
  } else {
    return (
      <Reset onClickReset={props.reset} style={absolute} />
    )
  }
}

Control.propTypes = {
  init: PropTypes.any,
  minutes: PropTypes.any,
  pause: PropTypes.any,
  paused: PropTypes.any,
  progress: PropTypes.func,
  reset: PropTypes.any,
  seconds: PropTypes.any,
  start: PropTypes.any
}


export class TimerApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: '07',
      init: false,
      paused: true,
      seconds: '00',
      initial: '07',
      dialogOpen: false,
    };
    this.secondsRemaining = (this.state.timer * 60) + Number(this.state.seconds)
  };

  tick = () => {
    var min = Math.floor(this.secondsRemaining / 60)
    var sec = this.secondsRemaining - (min * 60)
    this.setState({
      timer: min,
      seconds: sec
    });
    if (sec < 10) {
      this.setState({
        seconds: "0" + this.state.seconds,
      })
    }
    if (min < 10) {
      this.setState({
        timer: "0" + min,
      })

    }

    if (min === 0 & sec === 0) {
      clearInterval(this.interval);
      let textboxes = document.getElementsByClassName("Annotation-Textbox")
      for (var i = 0; i < textboxes.length; i++) {
        textboxes[i].disabled = true
      }
      document.getElementById("Dialog-button").click()
      this.props.setStage("timerEnded")
    }
    this.secondsRemaining--


  }

  startTimer = () => {
    this.interval = setInterval(this.tick, 1000);
    let time = this.state.timer;
    this.secondsRemaining = (time * 60) + Number(this.state.seconds)
    this.setState({ paused: false });
    this.setState({ init: true })
    this.props.setStage("midExercise")
  }

  stopTimer = () => {
    clearInterval(this.interval);
    console.log(this, "THIS")
    this.setState({ paused: true });
    this.setState({ dialogOpen: true })
    this.props.setStage("exercisePaused")
  }

  reset = () => {
    this.setState({ timer: '00', seconds: '10', paused: true });
    clearInterval(this.interval);
    this.secondsRemaining = this.state.initial * 60;
    this.props.setStage("ExercisePaused")
  }

  progress = () => {
    return (+this.secondsRemaining / (+this.state.initial * 60)) * 100
  }

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  render() {
    const handleClose = () => {
      this.setState({ dialogOpen: false });
      this.startTimer();
    };
    return (
      <Box className="Time-box" display="flex">
        <Box display="flex" alignItems="center">
          <Hidden smDown >
            <Box mr={1}>
              <Typography noWrap variant="h6">
                <Timer timer={this.state.timer} seconds={this.state.seconds} />
              </Typography>
            </Box>
          </Hidden>
          <Control
            progress={this.progress}
            init={this.state.init}
            paused={this.state.paused}
            start={this.startTimer}
            pause={this.stopTimer}
            reset={this.reset}
            minutes={this.state.timer}
            seconds={this.state.seconds}
          />
          <Backdrop style={{ zIndex: "2000", display: 'flex', flexDirection: 'column' }} open={this.state.dialogOpen} onClick={handleClose} TransitionComponent={this.Transition}>
            {/* <div style={{ width: '100%', height: 0, paddingBottom: '56%', position: 'relative' }}><iframe src="https://giphy.com/embed/huzB2q32Yz5y95EXe3" width="100%" height="100%" style="position:absolute" frameBorder="0" class="giphy-embed" allowFullScreen></iframe></div><p><a href="https://giphy.com/gifs/LEGOMastersFOX-creative-juices-richardandflynn-huzB2q32Yz5y95EXe3">via GIPHY</a></p> */}
            <Typography variant="h3">Click anywhere to resume</Typography>
            <Box height="80%" ><Gif /></Box>
          </Backdrop>
        </Box>
      </Box>
    );
  };
}

TimerApp.propTypes = {
  setStage: PropTypes.func
};