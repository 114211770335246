import PropTypes from "prop-types";
import React from 'react'

function Slider(props) {
    return (
        <wired-slider class="frame-component Slider" style={{ pointerEvents: "none", margin: "auto"  }}></wired-slider>
    )
}

Slider.propTypes = {
  activeAnno: PropTypes.any,
  color: PropTypes.any,
  elemCount: PropTypes.any
}

export default Slider




