import React from 'react'
import { Badge, Icon, withStyles, Portal, Popper, makeStyles } from '@material-ui/core';
import AnnoCard from './AnnoCard'
import ComponentsList from './ComponentsList'
import AnnoPopper from './AnnoPopper'
import EditIcon from '@material-ui/icons/Edit';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    scrollContainer: {
      height: 400,
      overflow: 'auto',
      marginBottom: theme.spacing(3),
    },
    scroll: {
      position: 'relative',
      width: '230%',
      backgroundColor: theme.palette.background.paper,
      height: '230%',
    },
    legend: {
      marginTop: theme.spacing(2),
      maxWidth: 300,
    },
    paper: {
      maxWidth: 400,
      overflow: 'auto',
    },
    select: {
      width: 200,
    },
    popper: {
      zIndex: 200000,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
        },
      },
    },
    arrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },
  }));
export default function ClickableComponent(props) {
    // const anchor = React.useRef();
    const [show, setShow] = React.useState(false);
    const [annotationText, updateAnnotation] = React.useState('')
    const [active, setActive] = React.useState(false);
    const [lock, setLock] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [arrowRef, setArrowRef] = React.useState(null);
    const classes = useStyles();

    const handleClick = (ev) => {
        setActive(!active)
        setShow(true)
        setAnchorEl(ev.currentTarget);
    }
    const open = Boolean(anchorEl);
    const openPopper = () => {
        props.setMobileOpen(false)
        props.handleDrawer(false)
        document.getElementById("component"+props.elemCount).click()
    };
    const handleClose = () => {
        if (annotationText && annotationText > 0) {
            setShow(true)
        }
        setActive(false)
        setAnchorEl(null);
    };
    const handleLock = () => {
        setLock(!lock)
    }
    const Component = ComponentsList[props.component]['html']
    let BadgeContent = 
            active ? <Icon><EditIcon style={{ color: 'white' }} /></Icon> :
                lock ? <Icon><CheckBoxIcon style={{ color: 'white' }} /></Icon> :
                        <Icon><CheckBoxOutlineBlankIcon style={{ color: 'white' }} /></Icon>

    const StyledBadge = withStyles((theme) => ({
        badge: {
            backgroundColor: `${active ? 'blue' : lock ? 'green': 'red'}`,
        },
    }))(Badge);
    return (
        <div>
            {show ? (
                <Portal container={props.annotationPortal.current}>
                    <AnnoCard
                        setShow={setShow}
                        // preview={<Component {...props} transform={true} scale={ComponentsList[props.component]['previewScale']}/>}
                        show={show}
                        lock={lock}
                        active={active}
                        openPopper={openPopper}
                        annotationText={annotationText}
                        type={ComponentsList[props.component]['type']}
                        guidanceInfo={ComponentsList[props.component]['tooltip']}
                        {...props}
                    ></AnnoCard>
                </Portal>
            ) : ''}
            <div id={"component"+props.elemCount} className={props.class ? "clickable "+props.class: "clickable"} onClick={handleClick}>
                <StyledBadge
                    invisible={!show}
                    badgeContent={BadgeContent}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Component {...props}/>
                </StyledBadge>
            </div>
            <Popper
                className={classes.popper}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                placement="right"
                disablePortal={false}
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    arrow: {
                        enabled: true,
                        element: arrowRef,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'viewport',
                    },
                }}>
                <AnnoPopper
                    key={props.elemCount}
                    setShow={setShow}
                    lock={lock}
                    handleLock={handleLock}
                    closePopper={handleClose}
                    datakey={props.elemCount}
                    type={ComponentsList[props.component]['type']}
                    guidanceInfo={ComponentsList[props.component]['tooltip']}
                    annotationText={annotationText}
                    updateLocalAnnotation={updateAnnotation}
                    {...props}
                />
                <span className={classes.arrow} ref={setArrowRef}></span>
            </Popper>
        </div>
    )
}
