import PropTypes from "prop-types";
import React from "react";
import ComponentsList from "../ComponentsList"
import "wired-elements";
import { Grid, Hidden } from '@material-ui/core'
import ClickableComponent from "../ClickableComponent"
import './Wireframe1.scss'

function randBetween(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function randomComponent(elementOptions) {
    const rand = randBetween(0, elementOptions.length - 1)
    const removedElem = elementOptions.splice(rand, 1);
    return removedElem;
}

function Wireframe1(props) {
    let headerComponents = [
        'Search',
        'Checkbox',
        'Slider',
        'Selector', 'Selector', 'Selector', 'Selector',
        'Button', 'Button'
    ]
    let bodyComponents = [
        'NavigationCard', 'NavigationCard', 'NavigationCard',
        'Calendar',
        'Video',
        'CardGallery',
        'Table',
    ]
    let menuComponents = [
        'MenuOption',
        'MenuOption',
        'MenuOption',
        'MenuOption',
        'MenuOption',
        'MenuOption'
    ]
    let elemCount = 1;
    let toolbar = [];
    for (let i = 0; i < props.randomizedProperties.toolbar.large; i++) {
        elemCount++;
        let selection = randomComponent(headerComponents);
        // const ComponentToRender = ComponentsList[selection]['component'];
        const randBool = randBetween(0, 100) > 50;
        const layout = randBool ? "horizontal" : "vertical";
        toolbar.push(
            <ClickableComponent
                key={elemCount}
                elemCount={elemCount}
                layout={layout}
                component={selection}
                {...props}
            />
        )
    }
    let body = [];
    for (let i = 0; i < props.randomizedProperties.components; i++) {
        elemCount++;
        let selection = randomComponent(bodyComponents);
        // const ComponentToRender = ComponentsList[selection]['component'];
        const randBool = randBetween(0, 100) > 50;
        const layout = randBool ? "horizontal" : "vertical";
        body.push({
            content: <ClickableComponent
                key={elemCount}
                elemCount={elemCount}
                layout={layout}
                component={selection}
                size={"small"}
                {...props}
            />,
            type: ComponentsList[selection]['type']
        })
    }
    let menu = [];
    for (let i = 0; i < props.randomizedProperties.menuItems; i++) {
        elemCount++;
        let selection = randomComponent(menuComponents);
        // const ComponentToRender = ComponentsList[selection]['component'];
        const randBool = randBetween(0, 100) > 50;
        const layout = randBool ? "horizontal" : "vertical";
        menu.push(<ClickableComponent
            key={elemCount}
            elemCount={elemCount}
            layout={layout}
            component={selection}
            {...props}
        />
        )
    }
    const randBool = randBetween(0, 100) > 50;
    const pageDirection = randBool ? 'column' : 'row';
    const otherDirection = !randBool ? 'column' : 'row';
    return (
        <div className="LayoutWires" style={{
            flexDirection: pageDirection,
            display: "flex",
            flexGrow: 1,
        }}>
            <Hidden smDown>
                <div className={'menu ' + otherDirection} style={{ flexDirection: otherDirection }}>
                    <div className='logo'><wired-icon-button>App Name</wired-icon-button></div>
                    <wired-card>
                        <div style={{ display: "flex", flexDirection: otherDirection }}>
                            {menu}
                        </div>
                    </wired-card>
                </div>
            </Hidden>
            <div className="body-content" style={{padding: "20px"}}>
                <div className="body-section" style={{ flexDirection: "row" }}>
                    {toolbar}
                </div>
                <Grid container justify="space-around" spacing={2}>
                    {body.map((tile, key) => {
                        return (tile.type === "video" ?
                            <Grid item style={{ flexGrow: 1 }} key={key}>
                                {tile.content}
                            </Grid> :
                            <Grid item key={key}>
                                {tile.content}
                            </Grid>
                        )
                    }
                    )}
                </Grid>
            </div>
        </div>
    )
}

Wireframe1.propTypes = {
  annotations: PropTypes.any,
  clickAction: PropTypes.any,
  colors: PropTypes.any,
  imageSizes: PropTypes.any,
  randomizedProperties: PropTypes.shape({
    components: PropTypes.any,
    menuItems: PropTypes.any,
    toolbar: PropTypes.shape({
      large: PropTypes.any
    })
  })
}


export default Wireframe1