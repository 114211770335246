import PropTypes from "prop-types";
import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import NightsStaySharpIcon from '@material-ui/icons/NightsStaySharp';
import WbSunnySharpIcon from '@material-ui/icons/WbSunnySharp';
import Tooltip from '@material-ui/core/Tooltip';

const style = {
    position: 'absolute',
    right: '0px',
    top: '8px',
    zIndex: 10000,
}


export function ThemeSwitcher(props) {
    if (props.darkState) {
        return (
            <div className="ThemeSwitcher" style={style}>
                <Tooltip title="Use Light Mode" >
                    <IconButton onClick={props.setDarkState}>
                        <WbSunnySharpIcon />
                    </IconButton>
                </Tooltip>
            </div>
        )
    } else {
        return (
            <div className="ThemeSwitcher" style={style}>
                <Tooltip title="Use Dark Mode"  >
                    <IconButton onClick={props.setDarkState}>
                        <NightsStaySharpIcon />
                    </IconButton>
                </Tooltip>
            </div>
        )
    }
}

ThemeSwitcher.propTypes = {
  darkState: PropTypes.any,
  setDarkState: PropTypes.any
}

// export default ThemeSwitcher;
