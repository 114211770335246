import PropTypes from "prop-types";
import React from 'react'
import { Grid } from '@material-ui/core';

function Card(props) {
    let imageLayout;
    let textBoxLayout = '';
    let itemSize = '';
    // let textboxHeaderSize = '';
    if (props.layout === "horizontal") {
        imageLayout = 3;
        textBoxLayout = 9;
        itemSize = "100%";
    }
    else {
        imageLayout = 12
        textBoxLayout = 12
        itemSize = 100 / props.cardCount + "%"
    }
    return (
        <wired-card style={{ width: itemSize }} onClick={props.clickEvent}>
            <Grid container>
                {/* <Grid item xs={imageLayout}><wired-image src={'https://placeholder.pics/svg/' + props.imageSizes.small.height + '/' + props.imageSizes.small.width}></wired-image></Grid> */}
                <Grid item xs={12} sm={imageLayout}><wired-card style={{ margin: "1em", backgroundColor: "rgba(192,192,192,0.7)", height: props.imageSizes.small.height, width: props.imageSizes.small.width }}></wired-card></Grid>
                <Grid item container xs={12} sm={textBoxLayout} alignItems="center" justify="center">
                    <Grid item xs={12} style={{ margin: "0 auto" }}><wired-card style={{ width: "75%", height: "100%" }} fill="rgba(192,192,192,0.7)"></wired-card></Grid>
                </Grid>
            </Grid>
        </wired-card>

    )
}

Card.propTypes = {
  cardCount: PropTypes.any,
  clickEvent: PropTypes.any,
  imageSizes: PropTypes.shape({
    small: PropTypes.shape({
      height: PropTypes.any,
      width: PropTypes.any
    })
  }),
  layout: PropTypes.string
}





function CardGallery(props) {
    let cardArray = [];
    for (let i = 0; i < props.randomizedProperties.cards[props.size]; i++) {
        cardArray.push(<Card layout={props.layout} key={i} imageSizes={props.imageSizes} />)
    }
    return (
        <wired-card class="frame-component CardGallery"
        style={{
            transform: 'scale('+props.scale+')',
        }}>
            <Grid container>
                {cardArray}
            </Grid>
        </wired-card>
    )
}

CardGallery.propTypes = {
  imageSizes: PropTypes.any,
  layout: PropTypes.any,
  randomizedProperties: PropTypes.shape({
    cards: PropTypes.any
  }),
  size: PropTypes.any
}

export default CardGallery