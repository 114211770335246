import PropTypes from "prop-types";
import React from "react";
import ClickableComponent from "../ClickableComponent"
import "wired-elements";
import { Grid } from '@material-ui/core'



// function randomComponent(elementOptions) {
//     const rand = randBetween(0, elementOptions.length - 1)
//     const removedElem = elementOptions.splice(rand, 1);
//     return removedElem;
// }

function Wireframe5(props) {
    let elemCount = 1;
    let menu = [];
    for (let i = 0; i < props.randomizedProperties.menuItems; i++) {
        elemCount++;
        // const randBool = randBetween(0, 100) > 50;
        // const layout = randBool ? "horizontal" : "vertical";
        menu.push(<Grid item>
                    <ClickableComponent
                        key={elemCount}
                        elemCount={elemCount}
                        {...props}
                        component={'MenuOption'}
                    />
                </Grid>
        )
    }
    let buttonArray = [];
    for (let i = 0; i < props.randomizedProperties.toolbar.small; i++) {
        elemCount++;
        console.log(elemCount, "ELEMCOUNT")
        buttonArray.push(<Grid item style={{ margin: 'auto' }}>
                            <ClickableComponent
                                key={elemCount}
                                elemCount={elemCount}
                                component={"Button"}
                                {...props}
                            />
                        </Grid>
        )
    }
    let navCardArray = [];
    // // for (let i=0; i< props.randomizedProperties.cards.medium; i++){
    for (let i = 0; i < 4; i++) {
        elemCount++;
        navCardArray.push(<Grid item>
                     <ClickableComponent
                            key={elemCount}
                            elemCount={elemCount}
                            size={"small"}
                            component={"NavigationCard"}
                            layout={"horizontal"}
                            {...props}
                        />
        </Grid>)

    }
    // let manualElemCount3 = elemCount+3
    return (
        <div className="LayoutWires">
            <Grid container spacing={2} justify="flex-end">
                <Grid item xs={12} container justify="space-between">
                    <Grid item xs={12} sm={3} style={{ textAlign: "left" }}><div className='logo'><wired-icon-button>App Name</wired-icon-button></div></Grid>
                    <Grid item xs={12} sm={9}>
                        <wired-card style={{ width: "100%" }}>
                            <Grid container justify="space-evenly">
                                {menu}
                            </Grid>
                        </wired-card>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={5} container>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                            <ClickableComponent
                            key={elemCount+1}
                            elemCount={elemCount+1}
                            component={"Calendar"}
                            {...props}
                        />
                            </Grid>
                            <Grid item container xs={12}>
                                {buttonArray}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={7} spacing={2} justify="space-around">
                    {navCardArray}
                </Grid>
                <Grid container item xs={12} sm={7} spacing={2}>
                    <Grid item xs={12}>
                    <ClickableComponent
                            key={elemCount+2}
                            elemCount={elemCount+2}
                            component={"Video"}
                            {...props}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

Wireframe5.propTypes = {
  annotations: PropTypes.any,
  clickAction: PropTypes.any,
  colors: PropTypes.any,
  imageSizes: PropTypes.any,
  randomizedProperties: PropTypes.shape({
    menuItems: PropTypes.any,
    toolbar: PropTypes.shape({
      small: PropTypes.any
    })
  })
}

export default Wireframe5