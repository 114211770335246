import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from'@material-ui/core/Icon';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepper: {
    padding: '24px 0',
  },
  button: {
    margin: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textCenter: {
    textAlign: 'center'
  }
}));

function getSteps() {
  return [
    'Select a Wireframe Component', 
    'Enter Annotation',// (Describe Desired Functionality)', 
    'All Finished?', 
    'Export Annotations'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Hover over the wireframe to see which elements are clickable, then select one that could be used in an application to solve your POV.';
    case 1:
      return 'Describe the component\'s functionality in the textbox located near the component. Ask yourself: "If this were a product that solved my problem, what would this element be used for?" Just type what you\'re thinking! All changes are automatically saved.';
    case 2:
      return 'Press the lock button when you\'d like to mark an annotation as complete. ...Tick, Tick, Tick... Annotate as many components as possible before the timer runs out! This is an ideation exercise so don\'t overanalyze, just brain dump any functionality that may be helpful and go for quantity over quality.';
    case 3:
        return 'Use the Download or Copy buttons to extract the completed annotations. These are the seedlings of the product requirements for your imagined solution to the stated POV. Review these with the rest of your team and refine until everyone\'s needs have been met, then get to building!';
    default:
      return 'Unknown stepIndex';
  }
}

export default function InstructionsStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div className={classes.textCenter}>
            <Typography className={classes.instructions}>Ready to Get Started?</Typography>
            <Button className={classes.button} color="primary" onClick={handleReset}><Icon><RotateLeftIcon/></Icon>&nbsp;Review</Button>
            <Button className={classes.button} color="primary" onClick={props.handleStart}><Icon><PlayCircleFilledWhiteIcon/></Icon>&nbsp;Let's Go!</Button>
          </div>
        ) : (
          <div className={classes.textCenter}>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button
                className={classes.button}
                color="primary"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button className={classes.button} color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}