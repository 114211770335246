import PropTypes from "prop-types";
import React from 'react'
class Button extends React.Component {
    shouldComponentUpdate(){
        return false
    }
    render(){
    return (
        <wired-button style={{
            pointerEvents: 'none',
            // transform: 'scale('+this.props.scale+')',
            margin: 'auto',
            textAlign: 'center'
        }} 
        class="frame-component Button">
            Button
        </wired-button>
    )}
}

Button.propTypes = {
    activeAnno: PropTypes.any,
    color: PropTypes.any,
    elemCount: PropTypes.any
}

export default Button