import PropTypes from "prop-types";
import React from 'react'
import { Grid } from '@material-ui/core';

function ButtonCard(props) {
    return (
    <wired-card class="frame-component ButtonCard" style={{ pointerEvents: "none", width: "100%" }}>
            <Grid container>
                <Grid item xs={3} sm={3}>
                    <wired-card style={{ backgroundColor: "rgba(192,192,192,0.7)", height: props.imageSizes[props.size].height, width: props.imageSizes[props.size].width }}></wired-card>
                </Grid>
                <Grid item xs={9} sm={6}>
                    <wired-card style={{ width: '75%', height: '100%' }} fill="rgba(192,192,192,0.7)"></wired-card>
                </Grid>
                <Grid item sm={3} xs={12} container>
                    <Grid item sm={12} xs={6}>
                        <wired-button style={{ pointerEvents: "none" }}>Button</wired-button>
                    </Grid>
                    <Grid item sm={12} xs={6}>
                        <wired-button style={{ pointerEvents: "none" }}>Button</wired-button>
                    </Grid>
                </Grid>
            </Grid>
        </wired-card>
    )
}

ButtonCard.propTypes = {
  color: PropTypes.any,
  elemCount: PropTypes.any,
  imageSizes: PropTypes.any,
  size: PropTypes.any
}

export default ButtonCard