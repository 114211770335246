import React from 'react';
import logo from '../logo.svg';
import { makeStyles, Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    logoIcon: {
        height: '2rem',
        animation: 'App-logo-spin infinite 20s linear',
    },
}));

export default function Logo() {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <img src={logo} className={classes.logoIcon} alt="logo" />
            <Box ml={1}><Typography variant="h6">ConstrUX</Typography></Box>
        </Box>
    )
}