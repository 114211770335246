import PropTypes from "prop-types";
import React from 'react'
import { Grid } from '@material-ui/core';


function ImageCard(props) {
    return (
        <wired-card class="frame-component ImageCard" style={{ width: "100%", pointerEvents: 'none' }}>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item xs={12}>
                        <wired-card style={{ backgroundColor: "rgba(192,192,192,0.7)", height: props.imageSizes[props.size].height, width: props.imageSizes[props.size].width }}></wired-card>
                    </Grid>
                    <Grid item xs={12}>
                        <wired-card style={{ width: "100%", height: "100%" }}>
                            <wired-card style={{ width: "75%", height: "2em" }} fill="rgba(192,192,192,0.7)"></wired-card>
                        </wired-card>
                    </Grid>
                </Grid>
            </wired-card>
    )
}

ImageCard.propTypes = {
  activeAnno: PropTypes.any,
  color: PropTypes.any,
  elemCount: PropTypes.any,
  imageSizes: PropTypes.any,
  size: PropTypes.any
}

export default ImageCard