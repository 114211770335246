import PropTypes from "prop-types";
import React from 'react'

function Selector(props) {
    return (
        <wired-combo class="frame-component Selector" style={{
            transform: 'scale('+props.scale+')',
            margin: 'auto',
            pointerEvents: 'none'
            }} selected="one">
            <option value="one">Option One</option>
            <option value="two">Option Two</option>
            <option value="three">Option Three</option>
        </wired-combo>
    )
}

Selector.propTypes = {
  elemCount: PropTypes.any
}

export default Selector

