import PropTypes from "prop-types";
import React from 'react'


function Calendar(props) {
    return (
            <wired-calendar style={{ pointerEvents: "none", margin: "auto"  }} class="frame-component Calendar"/>   )
}

Calendar.propTypes = {
  activeAnno: PropTypes.any,
  color: PropTypes.any,
  elemCount: PropTypes.any
}

export default Calendar