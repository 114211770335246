import PropTypes from "prop-types";
import React from "react";

import ClickableComponent from "../ClickableComponent"
import "wired-elements";
import { Grid } from '@material-ui/core'

function Wireframe6(props) {

    let elemCount = 1;
    let menu = [];
    for (let i=0; i < props.randomizedProperties.menuItems; i++){
        elemCount++;
        // const randBool = randBetween(0, 100) > 50;
        // const layout = randBool ? "horizontal": "vertical";
        menu.push(  <Grid item xs={12} style={{margin: "auto"}}>
                    <ClickableComponent
                        key={elemCount}
                        elemCount={elemCount}
                        {...props}
                        component={'MenuOption'}
                    />
                    </Grid>
                )
    }
    let buttonCardArray=[];
    for (let i=0; i < 4; i++){
        elemCount++
        buttonCardArray.push(
                    <Grid item xs={12}>
                    <ClickableComponent
                            class="width-100"
                            key={elemCount}
                            elemCount={elemCount}
                            size={"small"}
                            component={"ButtonCard"}
                            layout={"horizontal"}
                            {...props}
                        />
                    </Grid>
        )
    }
    let selectorArray = [];
    for (let i=0; i < 3; i++){
        elemCount++;
        console.log(elemCount, "ELEMCOUNT")
        selectorArray.push(<Grid item xs={12} style={{margin: "auto"}}>
                            <ClickableComponent
                                key={elemCount}
                                elemCount={elemCount}
                                component={"Selector"}
                                {...props}
                            />
                            </Grid>
         )
    }
    return(
        <div className="LayoutWires">
            <Grid container spacing={2}>
                <Grid item xs={12} style={{textAlign: "left"}}>
                    <div className='logo'><wired-icon-button>App Name</wired-icon-button></div>
                </Grid>
                <Grid item md={2} xs={12}>
                    <wired-card>
                        <Grid container>
                            {menu}
                        </Grid>
                    </wired-card>
                </Grid>
                <Grid item container sm={10} xs={12}>
                    <Grid container item xs={12}>
                        {buttonCardArray}
                    </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2} justify="flex-end">
                    <Grid md={3} sm={6} xs={12}>
                        <ClickableComponent
                            key={elemCount+1}
                            elemCount={elemCount+1}
                            size={"medium"}
                            component={"ImageCard"}
                            layout={"horizontal"}
                            {...props}
                        />
                    </Grid>
                    <Grid item md={3} sm={6} xs={12} container>
                        {selectorArray}
                        <Grid item xs={12} style={{margin: "auto"}}>
                        <ClickableComponent
                            key={elemCount+2}
                            elemCount={elemCount+2}
                            component={"Button"}
                            {...props}
                        />
                        </Grid>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                        <ClickableComponent
                                key={elemCount+3}
                                elemCount={elemCount+3}
                                component={"Video"}
                                {...props}
                            />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

Wireframe6.propTypes = {
  annotations: PropTypes.any,
  clickAction: PropTypes.any,
  colors: PropTypes.any,
  imageSizes: PropTypes.any,
  randomizedProperties: PropTypes.shape({
    menuItems: PropTypes.any
  })
}

export default Wireframe6