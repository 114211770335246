import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Card, CardActions, CardContent, Divider, Button, Box, Grid, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import axios from 'axios';
import { authMiddleWare } from '../utils/auth';

const styles = (theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: '4em'
    },
    toolbar: theme.mixins.toolbar,
    root: {},
    details: {
        display: 'flex'
    },
    avatar: {
        height: 110,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    },
    locationText: {
        paddingLeft: '15px'
    },
    buttonProperty: {
        position: 'absolute',
        top: '50%'
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    progess: {
        position: 'absolute'
    },
    uploadButton: {
        marginLeft: '8px',
        margin: theme.spacing(1)
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    },
    submitButton: {
        marginTop: '10px'
    }
});

class account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            username: '',
            country: '',
            profilePicture: '',
            uiLoading: true,
            buttonLoading: false,
            imageError: ''
        };
    }

    componentWillMount = () => {
        authMiddleWare(this.props.history);
        const authToken = localStorage.getItem('AuthToken');
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        axios
            .get('/user')
            .then((response) => {
                console.log(response.data);
                this.setState({
                    firstName: response.data.userCredentials.firstName,
                    lastName: response.data.userCredentials.lastName,
                    email: response.data.userCredentials.email,
                    phoneNumber: response.data.userCredentials.phoneNumber,
                    country: response.data.userCredentials.country,
                    username: response.data.userCredentials.username,
                    uiLoading: false
                });
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    this.props.history.push('/login');
                }
                console.log(error);
                this.setState({ errorMsg: 'Error in retrieving the data' });
            });
    };
    // logoutHandler = (event) => {
    //     localStorage.removeItem('AuthToken');
    //     this.props.history.push('/login');
    // };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleImageChange = (event) => {
        this.setState({
            image: event.target.files[0]
        });
    };

    profilePictureHandler = (event) => {
        event.preventDefault();
        this.setState({
            uiLoading: true
        });
        authMiddleWare(this.props.history);
        const authToken = localStorage.getItem('AuthToken');
        let form_data = new FormData();
        form_data.append('image', this.state.image);
        form_data.append('content', this.state.content);
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        axios
            .post('/user/image', form_data, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    this.props.history.push('/login');
                }
                console.log(error);
                this.setState({
                    uiLoading: false,
                    imageError: 'Error in posting the data'
                });
            });
    };

    updateFormValues = (event) => {
        event.preventDefault();
        this.setState({ buttonLoading: true });
        authMiddleWare(this.props.history);
        const authToken = localStorage.getItem('AuthToken');
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        const formRequest = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            country: this.state.country
        };
        axios
            .post('/user', formRequest)
            .then(() => {
                this.setState({ buttonLoading: false });
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    this.props.history.push('/login');
                }
                console.log(error);
                this.setState({
                    buttonLoading: false
                });
            });
    };

    render() {
        const { classes, ...rest } = this.props;
        if (this.state.uiLoading === true) {
            return (
                <div className={classes.content} style={{ outline: 0 }}>
                    <div className={classes.toolbar} />
                    {this.state.uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
                </div>
            );
        } else {
            return (
                <section style={{ height: '100vh' }}>
                    <div className={classes.content} style={{ outline: 0, margin: 'auto' }}>
                        <Box className={classes.toolbar} onClick={this.props.handleClose}>
                            <IconButton
                                style={{ float: "right", zIndex: 5000, color: 'white' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Card {...rest} className={clsx(classes.root, classes)}>
                            <CardContent>
                                <div className={classes.details}>
                                    <div>
                                        <Typography className={classes.locationText} gutterBottom variant="h4">
                                            {this.state.firstName} {this.state.lastName}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                            size="small"
                                            startIcon={<CloudUploadIcon />}
                                            className={classes.uploadButton}
                                            onClick={this.profilePictureHandler}
                                        >
                                            Upload Photo
									</Button>
                                        <input type="file" onChange={this.handleImageChange} />

                                        {this.state.imageError ? (
                                            <div className={classes.customError}>
                                                {' '}
											Wrong Image Format || Supported Format are PNG and JPG
                                            </div>
                                        ) : (
                                                false
                                            )}
                                    </div>
                                </div>
                                <div className={classes.progress} />
                            </CardContent>
                            <Divider />
                        </Card>

                        <br />
                        <Card {...rest} className={clsx(classes.root, classes)}>
                            <form autoComplete="off" noValidate>
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="First name"
                                                margin="dense"
                                                name="firstName"
                                                variant="outlined"
                                                value={this.state.firstName}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Last name"
                                                margin="dense"
                                                name="lastName"
                                                variant="outlined"
                                                value={this.state.lastName}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                margin="dense"
                                                name="email"
                                                variant="outlined"
                                                disabled={true}
                                                value={this.state.email}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Phone Number"
                                                margin="dense"
                                                name="phone"
                                                type="number"
                                                variant="outlined"
                                                disabled={true}
                                                value={this.state.phoneNumber}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="User Name"
                                                margin="dense"
                                                name="userHandle"
                                                disabled={true}
                                                variant="outlined"
                                                value={this.state.username}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Country"
                                                margin="dense"
                                                name="country"
                                                variant="outlined"
                                                value={this.state.country}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions />
                            </form>
                        </Card>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.submitButton}
                            onClick={this.updateFormValues}
                            disabled={
                                this.state.buttonLoading ||
                                !this.state.firstName ||
                                !this.state.lastName ||
                                !this.state.country
                            }
                        >
                            Save details
						{this.state.buttonLoading && <CircularProgress size={30} className={classes.progess} />}
                        </Button>
                        {/* <Button
                            color="primary"
                            variant="contained"
                            className={classes.submitButton}
                            onClick={this.logoutHandler}
                        >
                            Logout
                        </Button> */}
                    </div>
                </section>
            );
        }
    }
}

export default withStyles(styles)(account);