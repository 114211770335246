import { Box, Button, Container, SwipeableDrawer, Tooltip, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import React from 'react';
import { CSVLink } from 'react-csv';
import DropdownButton from './DropdownButton';

import Header from './Header';
import WireframeContainer from './WireframeContainer';

const drawerWidth = 320;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    pov: {
        padding: theme.spacing(1)
    },
    hide: {
        display: 'none'
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: {
        ...theme.mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    // toolButton: {
    //     margin: theme.spacing(1)
    // },
    drawerPaper: {
        width: drawerWidth,
        background: 'none',
    },
    main: {
        maxHeight: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100vh',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
}));
function DrawerContainer(props) {
    const annotationPortal = React.useRef(null);
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    // const [drawerOpen, toggleDrawerOpen] = React.useState(false);
    const [annotations, setAnnotations] = React.useState([]);

    // const updateAnnotation = (value, key, field) => {
    //     console.log("UPDATE!!!", [...arguments])
    //     let newArray = [...annotations]
    //     console.log(newArray, "1")
    //     newArray[key] = value;
    //     setAnnotation(newArray);
    //     console.log(newArray, "2")
    //     handleDrawerToggle(annotations.filter(val => !!val).length > 0)
    // }
    const captureAnnotations = () => {
        let annotations = props.getAnnotations();
        setAnnotations(annotations);
        return annotations;
    }
    const handleMobileDrawerToggle = () => {
        console.log("handleMobileDrawerToggle")
        setMobileOpen(!mobileOpen)
        props.handleDrawer(!mobileOpen)
    };
    // const handleDrawerToggle = (openState) => {
    //     toggleDrawerOpen(openState);
    // }
    // const copyData = () => {
    //     let stateArray = [];
    //     let indyAnnotation = []
    //     const annotations = document.getElementsByClassName("annotation-textfield")
    //     // console.log(this.state.annotations)
    //     for (let i = 0; i < annotations.length ; i++) {
    //         indyAnnotation.push(annotations[i].dataset.type + "-" + annotations[i].innerHTML)
    //     }
    //     stateArray.push(indyAnnotation)
    //     const excelData = stateArray.map(lines => lines.join("\t")).join("\n");
    //     copy(excelData, {
    //         format: "text/plain",
    //     })
    //     alert("Your annotations have been copied to your clipboard! Now, close this window and press ctrl+v to paste them to Miro.")
    // }

    const sidebarStyles = {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    }
    const drawerContent = (
        <Box style={sidebarStyles}>
            <Toolbar className={classes.toolbar} mt={2} mb={1} id="Download" styles={{ display: "flex", justifyContent: "space-evenly" }}>
                <Tooltip title="Annotations formatted in CSV">
                    <CSVLink id="Download-annotations"
                        data={annotations}
                        style={{
                            textDecoration: "none",
                            color: "none"
                        }}
                        filename={"exercise_data.csv"}
                        target="_blank">
                        <Box>
                            <Button onClick={captureAnnotations} className={classes.toolButton} variant="outlined" color="primary">
                                <GetAppIcon />&nbsp;CSV
                                </Button>
                        </Box>
                    </CSVLink>
                </Tooltip>
                {/* <Tooltip title="Copies annotations to clipboard"> */}
                    <Box>
                        <DropdownButton {...props} />
                    </Box>
                {/* </Tooltip> */}
            </Toolbar>
            <Box flexGrow={1} overflow="auto" id="portal">
                <div ref={annotationPortal}></div>
            </Box>
        </Box>
    )

    const mainContent = (
        <WireframeContainer annotationPortal={annotationPortal} handleMobileDrawerToggle={handleMobileDrawerToggle} setMobileOpen={setMobileOpen} handleDrawer={props.handleDrawer} />
    )

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <div className={classes.root}>
            <CssBaseline />
            <Header toggleMenu={handleMobileDrawerToggle} {...props} />
            <nav className={classes.drawer} aria-label="annotation section">
                <Hidden smUp implementation="js">
                    <SwipeableDrawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onOpen={() => { console.log("drawer opened"); return null }}
                        onClose={handleMobileDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawerContent}
                    </SwipeableDrawer>
                </Hidden>
                <Hidden xsDown implementation="js">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open={true}
                    // open={getAnnotations().length > 0}
                    >
                        {/* {console.log(getAnnotations().length > 0, "ANNO LENGTH")} */}
                        <Toolbar />
                        {drawerContent}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.main}>
                <Toolbar />
                <Box flexGrow="1" px={2} className={classes.pov}>
                    {/* <Hidden smDown > */}
                    <Typography variant="overline">
                        Point of View Statement
                        </Typography>
                    <Typography variant="body1">
                        <span>{props.povValue}</span>
                        {/* {props.stage === "Stage2" && <span>{props.povValue}</span>} */}
                    </Typography>
                    {/* </Hidden> */}
                </Box>
                <Container className={classes.content}>
                    {mainContent}
                </Container>
            </main>
        </div>
    );
}

DrawerContainer.propTypes = {
    drawerContent: PropTypes.element,
    handleDrawer: PropTypes.func,
    mainContent: PropTypes.element,
    window: PropTypes.func
};

export default DrawerContainer;