import React from 'react';

function randBetween(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min));
}
const gifs = [
    '5WJ6K7XnP2K2p3VWft',
    'huzB2q32Yz5y95EXe3',
    'xT0xem7ZlZ2DOYqpG0',
    '7XuPYJXaF1CBAmbwQQ',
    '9fydoEgKf8j7i',
    'tTA6WVVa4Zzri',
    'j2vRen5otKUE3e7Xk4',
    '7IQ3FrkqA1TyPFJDpi',
    'cNfIqjpCY1zqfaLmd8',
]

export default function randomGif() {
    const index = randBetween(0, gifs.length);
    const gifUrl = gifs[index];
    return (
        <img alt="gif" height="100%" src={`https://media.giphy.com/media/${gifUrl}/giphy.gif`} />
    )
}