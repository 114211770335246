import PropTypes from "prop-types";
import React from 'react';
import { TimerApp } from './Timer';
import Logo from './Logo';
import { Button, Hidden, Box, AppBar, Toolbar, makeStyles, Modal } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey';
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Account from './Account';
// const grey = Colors.grey;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: grey[600],
    },
    toolbar: {
        paddingRight: 48,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    }
}));



function Header(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleClick = () => {
        setOpen(true);
    }
    const goToLogin = () => {
        props.history.push('/login');
    }
    return (
        // <div>
        //     <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Hidden smUp implementation="css">
                    <Box onClick={props.toggleMenu} ><Logo /></Box>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Logo />
                </Hidden>
                <Box display="flex">
                    <div style={{ display: props.stage === "midExercise" ? '' : 'none' }}>
                        <TimerApp setStage={props.updateStage} />
                    </div>
                    {props?.user?.name ?
                        <div onClick={handleClick}>
                            <Tooltip title={props.user.name + ' (' + props.user.email + ')'} >
                                <Avatar alt={props.user.name} src={props.user.imageUrl} />
                            </Tooltip>
                        </div>
                        :
                        <Button color="primary" onClick={goToLogin}>Sign Up or Login</Button>
                    }

                    {/* {props.stage === "enterPOV" ? "" : <TimerApp setStage={props.updateStage} />} */}
                </Box>
            </Toolbar>
            <Modal
                open={open}
                onClose={handleClose}
                onBackdropClick={handleClose}
            >
                <Account {...props} handleClose={handleClose} />
            </Modal>
        </AppBar>
        // </div>
    );
}

Header.propTypes = {
    povValue: PropTypes.any,
    toggleMenu: PropTypes.any,
    updateStage: PropTypes.any
}

export default Header;


/* <header className="App-header">
            <Logo />
            <Hidden smDown>
                <div className="POV-statement">
                    <div>
                        <h3 style={{ textAlign: 'center' }}>Point of View Statement</h3>
                    </div>
                    <div style={{ padding: '0 5em' }}>
                        {props.stage === "Stage2" ? <p>{props.povValue}</p> : ''}
                    </div>
                </div>
            </Hidden>
        </header> */