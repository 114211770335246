import PropTypes from "prop-types";
import React from "react";
import "wired-elements";
import { Grid } from '@material-ui/core'
import ClickableComponent from "../ClickableComponent"

function randBetween(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function randomComponent(elementOptions) {
    const rand = randBetween(0, elementOptions.length - 1)
    const removedElem = elementOptions.splice(rand, 1);
    return removedElem;
}
function Wireframe3 (props) {
    console.log(props, "WIRE FRAME")
    let elemCount = 1;
    let menu = [];
    for (let i=0; i < props.randomizedProperties.menuItems; i++){
        elemCount++;
        // const randBool = randBetween(0, 100) > 50;
        // const layout = randBool ? "horizontal": "vertical";
        menu.push(  <Grid item xs={12} sm={2}>
                                <ClickableComponent
                        key={elemCount}
                        elemCount={elemCount}
                        {...props}
                        component={'MenuOption'}
                    />
                    </Grid>
                )
    }
    let variableTools = ['Search', 'Checkbox', 'Slider', "Checkbox"]
    let variableToolsArray = [];
    for (let i = 0; i < props.randomizedProperties.toolbar.small; i++){
        elemCount++;
        const selection = randomComponent(variableTools);
        variableToolsArray.push(
            <Grid item xs={12}>
                <ClickableComponent
                    key={elemCount}
                    elemCount={elemCount}
                    component={selection}
                    {...props}
                />
            </Grid>
        )
    }
    

    let selectorArray = [];
    // for (let i=0; i < props.randomizedProperties.toolbar.small; i++){    
    for (let i=0; i < 2; i++){
        elemCount++;
        selectorArray.push(
                        <Grid item xs={12} sm={12} md={6}>
                            <ClickableComponent
                                key={elemCount}
                                elemCount={elemCount}
                                component={"Selector"}
                                {...props}
                            />
                        </Grid>
         )
    }
    // let imageSize = window.innerWidth > 1024 ? "large" : "medium"
    return(
        <div className="LayoutWires">
        <Grid container spacing={2}>
            <Grid item xs={12} container justify="space-between">
                <Grid item sm={3} xs={12} style={{textAlign: "left"}}><div className='logo'><wired-icon-button>App Name</wired-icon-button></div></Grid>
                <Grid item sm={8} xs={12} style={{textAlign: "right"}}>
                    <wired-card style={{width: "100%"}}>
                        <Grid container justify="space-evenly">
                            {menu}
                        </Grid>
                    </wired-card>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item container sm={4} xs={12}>
                    <Grid item style={{width: "100%"}}>
                    <ClickableComponent
                            key={elemCount+1}
                            elemCount={elemCount+1}
                            size={"large"}
                            component={"CardGallery"}
                            layout={"horizontal"}
                            {...props}
                        />
                    </Grid>
                    <Grid item style={{width: "100%"}}>
                        <wired-card style={{width: "100%"}}>
                            <Grid container spacing={2}>
                                <Grid container item spacing={2} justify="space-evenly">
                                    {variableToolsArray}
                                </Grid>
                                <Grid container item spacing={2} justify="space-evenly">
                                    {selectorArray}
                                </Grid>
                                <Grid container item spacing={2} justify="flex-end">
                                    <Grid item>
                                    <ClickableComponent
                                        key={elemCount+2}
                                        elemCount={elemCount+2}
                                        component={"Button"}
                                        {...props}
                                    />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </wired-card>
                    </Grid>
                </Grid>
                <Grid item container justify="center" xs={12} sm={8}>
                    <Grid item xs={12}>
                    <ClickableComponent
                            key={elemCount+3}
                            elemCount={elemCount+3}
                            size={"large"}
                            component={"ImageCard"}
                            layout={"horizontal"}
                            {...props}
                        />
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            </div>
    )
}

Wireframe3.propTypes = {
  activeAnno: PropTypes.any,
  annotations: PropTypes.any,
  clickAction: PropTypes.any,
  colors: PropTypes.any,
  imageSizes: PropTypes.any,
  randomizedProperties: PropTypes.shape({
    menuItems: PropTypes.any,
    toolbar: PropTypes.shape({
      small: PropTypes.any
    })
  })
}

export default Wireframe3