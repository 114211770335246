import PropTypes from "prop-types";
import React from 'react'

function Checkbox(props) {
    return (
        <wired-checkbox class="frame-component Checkbox" style={{pointerEvents: 'none', margin: "auto"  }}></wired-checkbox>
    )
}

Checkbox.propTypes = {
  activeAnno: PropTypes.any,
  color: PropTypes.any,
  elemCount: PropTypes.any
}

export default Checkbox