import PropTypes from "prop-types";
import React from "react";
import "wired-elements";
import withWidth from '@material-ui/core/withWidth';
import Paper from '@material-ui/core/Paper'
import LayoutsList from './LayoutsList'

function randBetween(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}
let LayoutNumberToRender = randBetween(1, 6)
let imageSizes = {
    smallest: {
        height: randBetween(20, 50),
        width: randBetween(75, 150)
    },
    small: {
        height: randBetween(50, 100),
        width: randBetween(50, 100)
    },
    medium: {
        height: randBetween(100, 200),
        width: randBetween(100, 200)
    },
    large: {
        height: randBetween(500, 600),
        width: randBetween(500, 600)
    }
}
let randomizedProperties = {
    cards: {
        small: randBetween(3, 4),
        medium: randBetween(4, 5),
        large: randBetween(6, 8)
    },
    menuItems: randBetween(3, 5),
    toolbar: {
        small: randBetween(1, 2),
        medium: randBetween(2, 3),
        large: randBetween(4, 5),

    },
    grid: {
        small: {
            rows: randBetween(3, 4),
            columns: randBetween(3, 4)
        },
        medium: {
            rows: randBetween(5, 6),
            columns: randBetween(4, 5)
        },
        large: {
            rows: randBetween(10, 12),
            columns: randBetween(5, 6)
        }
    },
    components: randBetween(5, 6),
}
let colors = ["LemonChiffon", "LightBlue", "LightCoral", "LightCyan", "LightGoldenRodYellow", "LightGray", "LightGreen", "red", "orange", "LightPink", "LightSalmon", "LightSeaGreen", "LightSkyBlue", "LightSlateGray", "LightSlateGrey", "LightSteelBlue", "LightYellow"]
// export default function WireframeContainer(props) {
//     const [annotations, updateAnnotation] = React.useState({})

//     updateData(value, key, field) {
//         console.log("UPDATE!!!", [...arguments])
//         updateAnnotation = (state, props) => {
//             newState = { ...state }
//             newState[key] = value;
//             return newState;
//         }
//         newArray[key] = value;
//         this.setState({ annotations: newArray })
//     }
//     const LayoutToRender = LayoutsList["Wireframe" + LayoutNumberToRender]
//     return (
//         <Paper elevation={5} style={{ margin: 'auto', maxWidth: "1200px" }}>
//             <LayoutToRender
//                 colors={colors}
//                 randomizedProperties={randomizedProperties}
//                 imageSizes={imageSizes}
//                 // clickAction={this.handleClick}
//                 annotationPortal={props.annotationPortal}
//                 updateAnnotation={props.updateAnnotation}
//                 {...props}
//             // activeAnno={this.props.activeAnno}
//             />
//         </Paper>
//     )
// }
class WireframeContainer extends React.Component {
    shouldComponentUpdate = () => false
    render() {
        console.log(this.props, "THESE PROPS")
        const LayoutToRender = LayoutsList["Wireframe" + LayoutNumberToRender]
        return (
            <Paper elevation={5} style={{ margin: 'auto', maxWidth: "1200px" }}>
                <LayoutToRender
                    colors={colors}
                    randomizedProperties={randomizedProperties}
                    imageSizes={imageSizes}
                    annotationPortal={this.props.annotationPortal}
                    {...this.props}
                />
            </Paper>
        )
    }
}

WireframeContainer.propTypes = {
    annotationPortal: PropTypes.any,
}

export default withWidth()(WireframeContainer);