let ComponentsList = {};
ComponentsList['Button'] = {
    type: 'Button',
    tooltip: 'Hello',
    previewScale: 0.75,
    html:  require('./wireframe-components/Button').default
}
ComponentsList['Calendar'] = {
    type: 'Calendar',
    tooltip: 'Hello',
    previewScale: 0.5,
    html:  require('./wireframe-components/Calendar').default
}
ComponentsList['CardGallery'] = {
    type: 'Card Gallery',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/CardGallery').default
}
ComponentsList['Checkbox'] = {
    type: 'Checkbox',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/Checkbox').default
}
ComponentsList['MenuOption'] = {
    type: 'Menu Option',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/MenuOption').default
}
ComponentsList['NavigationCard'] = {
    type: 'Navigation Card',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/NavigationCard').default
}
ComponentsList['Search'] = {
    type: 'Search',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/Search').default
}
ComponentsList['Selector'] = {
    type: 'Selector',
    tooltip: 'Hello',
    previewScale: 0.5,
    html:  require('./wireframe-components/Selector').default
}
ComponentsList['Slider'] = {
    type: 'Slider',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/Slider').default
}
ComponentsList['Table'] = {
    type: 'Table',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/Table').default
}
ComponentsList['Video'] = {
    type: 'Video',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/Video').default
}
ComponentsList['ImageCard'] = {
    type: 'Image Card',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/ImageCard').default
}
ComponentsList['ButtonCard'] = {
    type: 'Button Card',
    tooltip: 'Hello',
    previewScale: 1,
    html:  require('./wireframe-components/ButtonCard').default
}


export default ComponentsList