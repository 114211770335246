import PropTypes from "prop-types";
import React from 'react'

function Video(props) {

    return (
        <wired-video class="frame-component Video" style={{ pointerEvents: 'none', margin: 'auto'}} autoplay muted loop src="" />
    )
}

Video.propTypes = {
  activeAnno: PropTypes.any,
  color: PropTypes.any,
  elemCount: PropTypes.any
}

export default Video
