import PropTypes from "prop-types";
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import InstructionsStepper from './InstructionsStepper'
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);

  };
  const handleContinueEditing = () => {
    setOpen(false)
    props.updateStage("midExercise")
  }
  const handleCopy = () => {
    handleClose();
    props.copyData();
  }
  const handleClickOpen = () => {
    console.log(props)
    setOpen(true);
  };
  const handleStart = () => {
    handleClose();
    props.updateStage("midExercise")
    document.getElementById("timer-start-pause-button").click();
  }
  const handleDownload = () => {
    document.getElementById("Download-annotations").click();
  }
  let modal = ''
  if (props.stage === "timerEnded") {
    modal = <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Time is up!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Do you want to continue editing or download your annotations?
                      </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-evenly" }}>
        <Button onClick={handleContinueEditing} color="primary">
          <EditIcon />&nbsp;Continue Editing
                      </Button>
        <Button onClick={handleDownload} color="primary">
          <GetAppIcon />&nbsp;Download
                      </Button>
        <Button onClick={handleCopy} color="primary">
          <FileCopyIcon />&nbsp;Copy
                      </Button>
      </DialogActions>
    </Dialog>
  }
  else if (props.stage === "preExercise" || props.stage === "enterPOV") {
    modal = <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      keepMounted
      style={{ paddingTop: "64px" }}
      //   onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"INSTRUCTIONS COPY"}
        <IconButton
          onClick={handleStart}
          style={{ float: "right" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-slide-description"> */}
        <InstructionsStepper handleStart={handleStart} />
        {/* </DialogContentText> */}
      </DialogContent>
    </Dialog>
  }
  return (
    <div>
      <Button id="Dialog-button" style={{ display: "none" }} variant="outlined" color="primary" onClick={handleClickOpen}>
        Slide in alert dialog
        </Button>
      {modal}
    </div>
  );
}

DialogModal.propTypes = {
  copyData: PropTypes.func,
  stage: PropTypes.string,
  updateStage: PropTypes.func
}
