import React from 'react';
import { Box, Paper, Tooltip, Grid, Collapse, TextField, Grow, Icon, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
    },
    buttonBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    annoBox: {
        flexGrow: 1,
        margin: '.5em',
    },
    lock: {
        height: '100%',
        paddingTop: '0 !important',
        // position: 'absolute',
        // left: '78%',
        // bottom: '60%'
    },
    collapse: {
        width: '100%',
        paddingTop: '0 !important'
    }
}));
export default function AnnoPopper(props) {
    const classes = useStyles();
    const [annotationText, updateAnnotation] = React.useState(props.annotationText)
    const handleChange = (event) => {
        updateAnnotation(event.target.value);
    };
    const handleBlur = () => {
        props.updateLocalAnnotation(annotationText);
        props.closePopper(annotationText);
        props.setShow(annotationText && annotationText.length > 0)
    }
    const handleLock = () => {
        props.handleLock();
        if (!props.lock === true){
            handleBlur()
        }
    }
    return (
        <form className={classes.root} noValidate autoComplete="off">
            <Box className="Annotation" style={{width: '20em'}}datakey={props.datakey}>
                <Tooltip title={props.guidanceInfo}>
                    <Paper elevation={10} className={classes.paper}>
                            <ClickAwayListener onClickAway={handleBlur} onClick={handleBlur}>
                            <Box className={classes.annoBox}>
                                <Grid container spacing={3} direction="column" alignItems="center">
                                    <Grid xs={12} item style={{width: '100%'}}>
                                        <TextField
                                            className="Annotation-Textbox"
                                            id={"anno" + props.datakey}
                                            style={{ width: '100%'}}
                                            label={props.type}
                                            multiline
                                            rows={4}
                                            disabled={props.lock ? true : false}
                                            autoFocus={true}
                                            placeholder="Describe how you might use this element"
                                            value={annotationText}
                                            onChange={handleChange}
                                            variant="outlined"
                                            onFocus={function (e) {
                                                var val = e.target.value;
                                                e.target.value = '';
                                                e.target.value = val;
                                            }}
                                        />
                                    </Grid>
                                    <Collapse className={classes.collapse} in={annotationText.length >0} style={{padding: annotationText.length > 0 ? '12px':'', paddingTop: '0'}}>
                                    <Grow
                                        in={annotationText.length > 0}
                                        style={{ transformOrigin: 'top' }}
                                        {...(annotationText.length > 0 ? { timeout: 2000 } : {})}
                                        >
                                        <Grid xs={12} item onClick={handleLock} className={classes.lock}>
                                            <Button color="primary" variant="outlined" style={{display: annotationText.length > 0 ? "":"none", height:'100%', width: '100%', padding:'8px 16px'}}>
                                                <Icon>{props.lock ? <LockIcon/> : <LockOpenIcon/> }</Icon>
                                            </Button>
                                        </Grid>
                                    </Grow>
                                    </Collapse>
                                </Grid>
                                </Box>
                            </ClickAwayListener>
                    </Paper>
                </Tooltip>
            </Box>
        </form>
    )
}