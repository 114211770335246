import React, { Component, useState } from 'react';
import WelcomeScreen from "../components/WelcomeScreen"
import Header from "../components/Header"
import { ThemeSwitcher } from "../components/ThemeSwitcher"
import './App.css';
import "wired-elements"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import DialogModal from '../components/DialogModal';
import DrawerContainer from '../components/DrawerContainer';
import copy from 'copy-to-clipboard';
import { authMiddleWare } from '../utils/auth';
import axios from 'axios';
console.log(process.env);
if (process.env.NODE_ENV !== "development") {
    axios.defaults.baseURL = "https://us-central1-sprinter-ideas.cloudfunctions.net/api";
}

function Theme(props) {
    const [darkState, setDarkState] = useState(false);
    const theme = createMuiTheme({
        palette: {
            primary: blueGrey,
            type: darkState ? 'dark' : 'light',
        },
    });
    const handleThemeChange = () => {
        setDarkState(!darkState);
        props.updateTheme(!darkState)
    };

    return (
        <ThemeProvider theme={theme} >
            <CssBaseline />
            <ThemeSwitcher darkState={darkState} setDarkState={handleThemeChange} />
            {props.children}
        </ThemeProvider>
    )
}

let wiredJSVariables = ["--wired-video-highlight-color", "--wired-calendar-color", "--wired-slider-knob-zero-color", "--wired-slider-knob-color", "--wired-slider-bar-color", "--wired-search-input-color"]
export default class Home extends Component {
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    logoutHandler = (event) => {
        localStorage.removeItem('AuthToken');
        this.props.history.push('/login');
    };

    componentWillMount = () => {
        authMiddleWare(this.props.history);
        const authToken = localStorage.getItem('AuthToken');
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        axios
            .get('/user')
            .then((response) => {
                // console.log(response.data);
                this.setState({
                    user: {
                        firstName: response.data.userCredentials.firstName,
                        lastName: response.data.userCredentials.lastName,
                        name: response.data.userCredentials.firstName + ' ' + response.data.userCredentials.lastName,
                        email: response.data.userCredentials.email,
                        phoneNumber: response.data.userCredentials.phoneNumber,
                        country: response.data.userCredentials.country,
                        username: response.data.userCredentials.username,
                        imageUrl: response.data.userCredentials.imageUrl
                    },
                    uiLoading: false,
                });
            })
            .catch((error) => {
                if (error?.response?.status === 403) {
                    this.props.history.push('/login');
                }
                console.log(error);
                if (this._isMounted) {
                    this.setState({ errorMsg: 'Error in retrieving the data' });
                }
            });
    };

    constructor(props) {
        super(props);
        this.state = {
            povValue: '',
            stage: 'enterPOV',
            export: false,
            mobile: false,
            drawerOpen: false,
            user: {},
            uiLoading: true,
            imageLoading: false
        }
        this.updateUser = this.updateUser.bind(this)
        this.updateStage = this.updateStage.bind(this)
        this.updateTheme = this.updateTheme.bind(this)
        this.handleDrawer = this.handleDrawer.bind(this)
        this.submitPOV = this.submitPOV.bind(this)
    }
    updateUser(user) {
        this.setState({ user: user })
    }
    updateStage(value) {
        this.setState({ stage: value })
    }
    handleDrawer(value) {
        this.setState({ drawerOpen: value })
    }
    copyData() {
        document.getElementById('Copy-dialog-button').click()
        let stateArray = [];
        let indyAnnotation = []
        const annotations = document.getElementsByClassName("annotation-textfield")
        // console.log(this.state.annotations)
        for (let i = 0; i < annotations.length; i++) {
            indyAnnotation.push(annotations[i].dataset.type + "-" + annotations[i].innerHTML)
        }
        stateArray.push(indyAnnotation)
        const excelData = stateArray.map(lines => lines.join("\t")).join("\n");
        copy(excelData, {
            format: "text/plain",
        })
        // alert("Your annotations have been copied to your clipboard! Now, close this window and press ctrl+v to paste them to Miro.")
    }
    getAnnotations() {
        const annotations = document.getElementsByClassName("annotation-textfield")
        // console.log(annotations, "ANNOTATIONS")
        let data = [];
        for (let i = 0; i < annotations.length; i++) {
            let object = {}
            object["Type"] = annotations[i].dataset.type
            object["Text"] = annotations[i].innerHTML
            data.push(object)
        }
        // console.log(data, "data - ANNOTATIONS")
        return data;
    }
    updateTheme(value) {
        this.setState({ darkState: value })
        if (value === true) {
            for (let i = 0; i < wiredJSVariables.length; i++) {
                document.documentElement.style.setProperty(wiredJSVariables[i], "white")
            }
        }
        else {
            for (let i = 0; i < wiredJSVariables.length; i++) {
                document.documentElement.style.setProperty(wiredJSVariables[i], "black")
            }
        }
        // console.log(document.documentElement.style, "STYLE!!!!!!!!!!!!!!!!!")
    }
    submitPOV() {
        // console.log("SUBMITTED")
        this.setState({ stage: "preExercise" })
        document.getElementById("Dialog-button").click()
    }
    render() {

        return (
            <Theme updateTheme={this.updateTheme}>
                <div className="App">
                    {this.state.stage === "enterPOV" ?
                        <div>
                            <Header stage={this.state.stage} user={this.state.user} />
                            <Toolbar />
                            <WelcomeScreen setStage={this.updateStage} pov={this.state.povValue} submitPOV={this.submitPOV} setPov={(povValue) => this.setState({ povValue: povValue })} />
                        </div>
                        :
                        <section>
                            <DrawerContainer
                                handleDrawer={this.handleDrawer}
                                povValue={this.state.povValue}
                                updateStage={this.updateStage}
                                stage={this.state.stage}
                                copyData={this.copyData}
                                getAnnotations={this.getAnnotations}
                                user={this.state.user}
                                updateUser={this.updateUser}
                            />
                        </section>
                    }
                    <footer className="App-footer">
                        <div>Made with <span role="img" aria-label="heart">❤️</span> by Sprinter</div>
                    </footer>
                    <DialogModal stage={this.state.stage} updateStage={this.updateStage} copyData={this.copyData} />
                </div>
            </Theme>
        );
    }
}